<template>
    <div>
        <div class="hasQuest" v-for="(item, index) in quest_info.vote_list" :key="index">
            <div class="quest_info">
                <span class="icon-vote"></span>
                <div class="repic_check">
                    <span v-if="item.type == 0">单选</span>
                    <span v-else>
                        多选
                        <template v-if="item.min_select && item.max_select">
                            <template v-if="item.min_select == item.max_select">
                                ，可选{{ item.min_select }}项
                            </template>
                            <template v-else>，可选{{ item.min_select }} - {{ item.max_select }}项</template>
                        </template>
                        <span v-else-if="item.min_select">，至少选{{ item.min_select }}项</span>
                        <span v-else-if="item.max_select">，至多选{{ item.max_select }}项</span>
                    </span>
                </div>
                <div class="tp_if">{{ rightMap[quest_info.vote_right] }}</div>
            </div>
            <div class="vote_title">
                {{item.topic_name}}
            </div>
            <div :class="['vote-all', quest_info.if_end == 1 ||  item.is_select_vote == 1 ? 'end' : '']">
                <template v-for="(sItem, sindex) in item.items" :key="sindex">
                    <div 
                        :class="['li', sItem.is_select_item ? 'checked' : '', checked_vote_items && checked_vote_items.length && checked_vote_items.includes(sItem.id) ? 'checked' : '']" 
                        @click.stop="checkVote(sItem, sindex, item, index)">
                        <div v-if="item.is_select_vote || quest_info.if_end == 1" class="precent" :style="`width:${sItem.percent}%;`"></div>
                        <div class="vote_cont">
                            <div v-if="sItem.img" class="img" @click.stop>
                                <el-image
                                    style="width: 60px; height: 60px;"
                                    :src="imgUrlFilter(sItem.img, 60)"
                                    :preview-src-list="[sItem.img]"
                                    fit="cover">
                                </el-image>
                            </div>
                            <div class="vote_txt render-line-clamp">{{sItem.title}}</div>
                            <div v-if="item.is_select_vote || quest_info.if_end == 1" class="vote_cont_precent">
                                <div>{{sItem.vote_num}}票</div>
                                <div>{{sItem.percent}}%</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="vote-info">
                <template v-if="item.vote_user_num">
                    <span>{{item.vote_user_num}}</span>人已投票 · 
                </template>
                <template v-if="quest_info.if_end == 1">已结束</template>
                <template v-else-if="endTimeShow && endTimeShow.length">还有
                    {{endTimeShow[endTimeShow.length - 1]}}
                    <template v-if="endTimeShow.length == 4">天</template>
                    <template v-else-if="endTimeShow.length == 3">小时</template>
                    <template v-else-if="endTimeShow.length == 2">分</template>
                    <template v-else-if="endTimeShow.length == 1">秒</template>结束
                </template>
            </div>
            <!-- 多选时显示投票 -->
            <div v-if="item.type == 1 && quest_info.if_end == 0 && checked_vote_items.length" class='vote-submit' @click='sub_vote(item)'>投票</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import { env } from 'md-base-tools/env'
import { projectVote } from "@/api/index"
import { goToLogin } from "@/utils/user"
import { fmTime } from '@/utils/tools'
import imgUrlFilter from 'md-base-tools/imgUrlFilter'
import { ElMessageBox, ElMessage } from "element-plus";
export default {
    props: {
        id: { // 更新id | 创意id
            type: String,
            default: ''
        },
        quest_info: {
            type: Object
        },
        vote_type: { // 1 创意 2 众筹
            type: String,
            default: '2'
        }
    },
    computed: {
        ...mapState({
            ifLogin: state => state.user.ifLogin, // 是否登录
            userId: state => state.user.userId,
            token: state => state.user.token,
            linkUrl: state => state.user.linkUrl,
        }),
        isPhone() {
            return env.isModianIos() || env.isModianAndroid()
        },
        endTimeShow () {
            if (this.quest_info.if_end == 1) {
                return ''
            } else {
                return fmTime(this.endtime)
            }
        },
    },
    data() {
        return {
            checked_vote_items: [], //投票选中数据
            showLoading: false,
            rightMap: {
                0: '',
                1: '仅支持者参与投票',
                2: '部分档位支持者参与投票'
            },
            endtime: '',
            endtime_timer: null // 计时器
        }
    },
    mounted() {
        // 已结束
        if (this.quest_info.if_end != 1 && this.quest_info.etime) {
            const time = (new Date(this.quest_info.etime).getTime() - new Date().getTime()) / 1000
            if (time > 0) {
                this.endtime = time
                this.endtime_timer = setInterval(() => {
                    if ( this.endtime > 0) {
                        this.endtime -= 1
                    } else {
                        clearInterval(this.endtime_timer)
                        window.location.reload()
                    }
                }, 1000)
            }
        }
    },
    beforeUnmount() {
        clearInterval(this.endtime_timer)
    },
    methods: {
        checkVote(sItem, sindex, item) {
            if (this.ifLogin && this.userId) {
                // 投票结束
                if (this.quest_info.if_end == 1) return
                // 已投过票
                if (item.is_select_vote) {
                    ElMessageBox.confirm(
                        "确定要取消投票吗？",
                        "提示",
                        {
                        distinguishCancelAndClose: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        }
                    )
                    .then(() => {
                        let option = {
                            item_update_id: this.id,
                            item_type: this.vote_type,
                            vote_id: item.id,
                            op_type: 0
                        }
                        this.doVote(option)
                    })
                    .catch((action) => {
                        console.log(action)
                    });
                } else { // 未投过票
                    // 单选
                    if (item.type == 0) {
                        let option = {
                            item_update_id: this.id,
                            item_type: this.vote_type,
                            vote_id: item.id,
                            vote_item_ids: sItem.id,
                            op_type: 1
                        }
                        this.doVote(option)
                    } else { // 多选
                        let index = this.checked_vote_items.findIndex(item => item == sItem.id)
                        if (index > -1) {
                            this.checked_vote_items.splice(index, 1)
                        } else {
                            if (item.max_select && this.checked_vote_items.length == item.max_select) {
                                ElMessage({
                                    type: 'warning',
                                    customClass: 'waring-customize',
                                    message: `至多选择${item.max_select}项`,
                                });
                            } else {
                                this.checked_vote_items.push(sItem.id)
                            }
                        }
                    }
                }
            } else {
                ElMessage({
                    type: 'warning',
                    customClass: 'waring-customize',
                    message: '请登录！',
                });
                goToLogin()
            }
        },
        // 多选投票
        sub_vote(item) {
            const _this =this
            if (this.ifLogin && this.userId) {
                if (item.min_select && this.checked_vote_items.length < item.min_select) {
                    ElMessage({
                        type: 'warning',
                        customClass: 'waring-customize',
                        message: `至少选择${item.min_select}项`,
                    });
                    return false;
                } 
                if (item.max_select && this.checked_vote_items.length > item.max_select) {
                    ElMessage({
                        type: 'warning',
                        customClass: 'waring-customize',
                        message: `至多选择${item.max_select}项`,
                    });
                    return false;
                }
                let option = {
                    item_update_id: _this.id,
                    item_type: _this.vote_type,
                    vote_id: item.id,
                    vote_item_ids: _this.checked_vote_items.join(','),
                    op_type: 1
                }
                _this.doVote(option)
            } else {
                ElMessage({
                    type: 'warning',
                    customClass: 'waring-customize',
                    message: '请登录！',
                });
                goToLogin()
            }
        },
        // 投票
        async doVote(option) {
            if (this.showLoading) return
            this.showLoading = true
            const { status, message } = await projectVote(option)
            this.showLoading = false
            if (status != 0) {
                ElMessage({
                    type: 'warning',
                    customClass: 'waring-customize',
                    message: message,
                });
            } else {
                ElMessage({
                    type: 'info',
                    customClass: 'info-customize',
                    message: option.op_type == 1 ? '投票成功！' : '取消成功！',
                });
                window.location.reload()
            }
        },
        imgUrlFilter() {
            return imgUrlFilter(...arguments)
        },
    }
}
</script>

<style lang="scss" scoped>
.loading-box {
    position: absolute;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
}
.toast-class {
    width: 250px;
}
.toast-btn-class {
    top: auto;
    bottom: 80px;
}
.hasQuest {
    background: #F2F2F2;
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    height: auto;
    padding:0 20px;
    margin:0 auto;
    .quest_info {
        font-size: 16px;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 16px;
        padding:30px 0 24px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .repic_check {
            font-size: 14px;
            margin-right: auto;
            margin-left: 5px;
        }
        .tp_if {
            font-size: 12px;
        }
    }
    .vote_title {
        font-size: 24px;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 1.5;
        padding-bottom: 20px;
    }
    .vote-all {
        width: 100%;
        &.end .li{
            color: #7F7F7F;
        }
        .li {
            margin-top:10px;
            width: 650px;
            height: 80px;
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #FFFFFF;
            padding:0 20px;
            box-sizing:border-box;
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            color: #000000;
            &.checked {
                border: 2px solid #00C4A1;
            }
            .precent {
                position: absolute;
                top: 0;
                left: 0;
                height: 80px;
                background: #D9F6F1;
                border-radius: 6px 0px 0px 6px;
            }
            .vote_cont {
                width: 610px;
                box-sizing: border-box;
                position: absolute;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 400;
                color: #000000;
                line-height: 24px;
                .img {
                    width: 60px;
                    height: 60px;
                    overflow: hidden;
                    border-radius: 8px;
                    margin-right: 20px;
                }
                .vote_txt {
                    flex: 1;
                }
                .vote_cont_precent {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                    line-height: 16px;
                    >div:first-child{
                        padding-bottom:10px;
                    }
                }
            }
        }
    }

    .vote-info {
        font-size: 16px;
        font-weight: 400;
        color: #7F7F7F;
        line-height: 16px;
        padding:20px 0 30px 0;
    }

    .vote-submit {
        width: 120px;
        height: 40px;
        background: #00C4A1;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        text-align: center;
        line-height: 40px;
        margin: 0 auto 30px;
    }
}
</style>
