<template>
    <div class="draw-new-box" @click="goDrawInfo">
        <img class="draw-new-box-bg" src="https://p6.moimg.net/mdwxapp/draw/draw_card_bg.png" />
        <img class="draw-new-img" :src="imgUrlFilter(drawInfo.img, 187 * radio)" />
        <div class="draw-new-info">
            <div class="top">抽奖活动</div>
            <div class="tit">{{drawInfo.title}}</div>
        </div>
        <div class="draw-new-btn">去抽奖</div>
    </div>
    <el-dialog v-model="qrcodeVisible" title="查看详情" :width="420">
        <div class="qrcode">
            <img :src="qrcode" />
            <span>微信扫一扫，查看详情</span>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="qrcodeVisible = false">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import imgUrlFilter from 'md-base-tools/imgUrlFilter'
import { domPixelRatio } from 'md-base-tools/env'
import { getMiniAppQrcode } from "@api/index"
import { ElMessageBox, ElLoading } from "element-plus";
export default {
    props: {
        drawInfo: {
            type: Object
        }
        // drawId: { // 投票id
        //     type: String
        // },
        // drawImage: {
        //     type: String
        // },
        // drawTitle: {
        //     type: String
        // }
    },
    // computed: {
    //     showImg() {
    //         return this.drawImage || this.img
    //     },
    //     showTit() {
    //         return this.drawTitle || this.tit
    //     }
    // },
    // watch: {
    //     "drawId": {
    //         handler: function(val) {
    //             if (val) {
    //                 this.getDrawInfo()
    //             }
    //         },
    //         immediate: true,
    //         deep: true
    //     }
    // },
    data() {
        return {
            radio: parseInt(domPixelRatio()) || 1,
            // img: '',
            // tit: '',
            // update_id: 0,
            qrcodeVisible:false,
            qrcode: '',
            qrcodeList: []
        }
    },
    methods: {
        goDrawInfo() {
            const qrcodeDraw = this.qrcodeList.find(obj => obj.activity_id === this.drawInfo.drawId);
            if (qrcodeDraw) {
                this.qrcode = qrcodeDraw.qrcode;
                this.qrcodeVisible = true;
            } else if (this.drawInfo.update_id > 0) {
                location.href = '/project/update/detail/' + this.drawInfo.update_id
            }else{
                const loading = ElLoading.service({
                    lock: true,
                    text: '请求中',
                })
                let option = {
                    page: 'pages/prize/detail/detail',
                    scene: 'activity_id='+this.drawInfo.drawId
                }
                getMiniAppQrcode(option).then(res => {
                    loading.close()
                    if(res.status == 0){
                        this.qrcodeList.push({
                            activity_id:this.drawInfo.drawId,
                            qrcode:res.data.qrcode
                        })
                        this.qrcode = res.data.qrcode;
                        this.qrcodeVisible = true;
                    }else{
                        ElMessageBox.alert(res.message, '提示', {
                            confirmButtonText: '确定'
                        })
                    }
                })
            }
        },
        imgUrlFilter() {
            return imgUrlFilter(...arguments)
        },
        // getDrawInfo() {
        //     if(this.drawImage && this.drawTitle) return
        //     getNewDrawInfo({
        //         activity_id: this.drawId
        //     }).then(res => {
        //         if (res.status == 0) {
        //             this.img = res.data.img
        //             this.tit = res.data.title
        //             this.update_id = res.data.update_id
        //         }
        //     })
        // }
    }
}
</script>

<style lang="scss" scoped>
.draw-new-box {
    cursor:pointer;
    width: 100%;
    height: 100px;
    border-radius: 12px;
    background: linear-gradient(90deg, #00C4A1 0%, #00C4A1 32%, #407A70 100%);
    color: #fff;
    // margin: 32px 0;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 24px;
    position: relative;
    .draw-new-img {
        width: 187px;
        height: 80px;
        border-radius: 6px;
        margin: 0 20px 0 10px;
    }
    .draw-new-info {
        flex: 1;
        width: 0;
        .top {
            opacity: 0.8;
        }
        .tit {
            font-size: 28px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 10px;
        }
    }
    .draw-new-btn {
        width: 120px;
        background-color: #00C4A1;
        border-radius: 4px;
        text-align: center;
        line-height: 48px;
        font-weight: 500;
        margin: 0 26px 0 10px;
    }
    .draw-new-box-bg {
        position: absolute;
        width: 100px;
        height: 90px;
        right: 156px;
        bottom: 0;
        opacity: 0.5;
    }
    ::v-deep(.el-dialog){
        .el-dialog__header{
            height:60px;
            padding:0;
            margin: 0 30px;
            font-size: 20px;
            font-weight: 600;
            line-height: 60px;
            color: #1A1A1A;
            border-bottom: 1px solid #E8E8E8;
        }
        .el-dialog__body{
            padding:20px 30px;
            .qrcode{
                display:flex;
                align-items:center;
                flex-direction:column;
                justify-content: space-between;
                img{
                    margin:16px 0 0 0;
                    width: 200px;
                    height: 200px;
                }
                span{
                    font-size: 16px;
                    padding:10px 0 0 0;
                    font-weight: 400;
                    color: #7F7F7F;
                    line-height: 16px;
                }
            }
        }
        .el-dialog__footer{
            .dialog-footer{
                display:flex;
                align-items:center;
                justify-content: end;
            }
        }
    }
}
</style>
