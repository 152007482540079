<template>
    <div>  
        <!-- 图片展示 -->
        <template v-if="imgs.length">
            <div class="prize-img-list">
                <template v-if="imgs.length == 1">
                    <img v-for="(item, index) in imgs" :key="index" class="one-img" :src="$imgUrlFilter(item, 750)" @click="showBigImg(index)" />
                </template>
                <template v-else-if="imgs.length == 2 || imgs.length == 3">
                    <img v-for="(item, index) in imgs" :key="index" class="two-img" :src="$imgUrlFilter(item, 680)" @click="showBigImg(index)" />
                </template>
                <template v-else>
                    <img v-for="(item, index) in imgs" :key="index" class="three-img" :src="$imgUrlFilter(item, 444)" @click="showBigImg(index)" />
                </template>
            </div>
        </template>
        <!-- 普通抽奖 -->
        <template v-if="updateInfo.type == 2">
            <div class="draw_detail draw_detail_lottery">
                <div class="draw_label">
                    <div class="strong">奖品</div>
                    <div class="rule" @click="showRule">
                        抽奖规则
                        <text class="iconfont icon-nestmore"></text>
                    </div>
                </div>
                <template v-for="(item, index) in updateInfo.prize_info" :key="index">
                    <div class="prize-p">
                        {{item.prize_name}}，共{{item.win_number}}人
                    </div>
                </template>
                <div class="draw_label six">
                    <div class="strong">参与条件</div>
                </div>
                <div class="prize-ul">
                    <div v-for="(item, index) in ruleArr" :key="index" class="prize-li">
                        <div class="prize-li-label">
                            {{index + 1}}. {{item.title}}
                        </div>
                        <div v-if="item.label||item.title.includes('支持/购买项目')">
                            <template v-if="item.title.indexOf('分享') > -1 && updateInfo.activity_info.rule_two">
                                <div class="share-container">
                                    <div class="draw_label">
                                        <div class="strong label-title">分享方式：</div>
                                    </div>
                                    <div class="prize-tips prize-tips-label">{{updateInfo.activity_info.rule_two}}</div>
                                    <div class="eg_cover lp_flex lptems_center lp_justify lptems_center">
                                        <img class="prize-img" src="https://s.moimg.net/images/draw_cover_1.png" />
                                        <img class="prize-img" src="https://s.moimg.net/images/draw_cover_2.png" />
                                    </div>
                                    <div class="draw_label">
                                        <div class="strong label-title">分享时间：</div>
                                    </div>
                                    <template v-if="updateInfo.activity_info.share_stime != '0000-00-00 00:00:00' || updateInfo.activity_info.share_etime != '0000-00-00 00:00:00'">
                                        <div class="draw_label_time">
                                            <div class="time-label">需在 {{updateInfo.activity_info.share_stime}} 至 {{updateInfo.activity_info.share_etime}} 期间操作分享，否则无法参与抽奖</div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="draw_label_time">
                                            <div class="time-label">任何时间分享均可参与抽奖</div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="item.title.indexOf('支持/购买项目') > -1 && (updateInfo.activity_info.reward_name || Number(updateInfo.activity_info.full_money) > 0)">
                                <div class="share-container">
                                    <div class="draw_label">
                                        <div class="strong label-title">支持/购买方式：</div>
                                    </div>
                                    <template v-if="Number(updateInfo.activity_info.full_money) > 0">
                                        <div class="prize-tips prize-tips-label">在项目中支付金额达到 ¥{{updateInfo.activity_info.full_money}} 即可参与抽奖</div>
                                        <div class="prize-tips-line"></div>
                                        <div class="draw_label">
                                            <div class="strong label-title">抽奖倍率计算：</div>
                                        </div>
                                        <div class="draw_label_time">
                                            <div v-if="updateInfo.activity_info.draw_type == 1" class="time-label">符合参与条件时，每个用户中奖机会相等</div>
                                            <div v-else-if="updateInfo.activity_info.draw_type == 2" class="time-label">用户支持项目金额每满{{updateInfo.activity_info.full_money}}元，获得1次参与抽奖机会</div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="prize-tips prize-tips-label">{{updateInfo.activity_info.reward_name}}</div>
                                        <div class="prize-tips-line"></div>
                                        <div class="draw_label">
                                            <div class="strong label-title">抽奖倍率计算：</div>
                                        </div>
                                        <div class="draw_label_time">
                                            <div v-if="updateInfo.activity_info.draw_mode == 1" class="time-label">符合参与条件时，每个用户中奖机会相等</div>
                                            <div v-else-if="updateInfo.activity_info.draw_mode == 2" class="time-label">用户每支付一笔符合参与条件的订单，获得 1 倍中奖机会（例如支付1单中奖机会=1倍，支付2单中奖机会</div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="item.title.indexOf('带话题参与项目讨论')  > -1 && updateInfo.activity_info.rule_five">
                                <div class="share-container">
                                    <div class="draw_label">
                                        <div class="strong label-title">评论方式：</div>
                                    </div>
                                    <div class="prize-tips prize-tips-label">带话题词 <span style="color: #00C4A1;">{{updateInfo.activity_info.rule_five.topic}}</span> 在项目或更新讨论区发布评论参与话题互动</div>
                                    <div class="prize-tips-line"></div>
                                    <div class="draw_label">
                                        <div class="strong label-title">评论示例：</div>
                                    </div>
                                    <div class="prize-tips prize-tips-label" v-html="getText(updateInfo.activity_info.rule_five.topic_example, updateInfo.activity_info.rule_five.topic)"></div>
                                </div>
                            </template>
                            <span v-else>{{item.label}}</span>
                        </div>
                    </div>
                </div>
                <div class="draw_label draw_label_mode">
                    <div class="strong">开奖方式</div>
                </div>
                <div>
                    <template v-if="updateInfo.activity_info.draw_time == '0000-00-00 00:00:00'">
                        项目发起人手动开奖
                    </template>
                    <template v-else>
                        已设置{{updateInfo.activity_info.draw_time}}，开奖过程可能需要几分钟时间，请耐心等待。
                    </template>
                </div>
                <div class="show_draw_btn" @click="showDrawResult">
                    <span class="iconfont icon-gift"></span>查看开奖情况
                </div>
            </div>
        </template>
        <!-- 助力抽奖 -->
        <template v-if="updateInfo.type == 3">
            <div class="draw_detail">
                <div class="draw_label">
                    <div class="strong">奖品</div>
                    <div class="rule" @click="showRule">抽奖规则<text class="iconfont icon-nestmore"></text></div>
                </div>
                <template v-for="(item, index) in updateInfo.prize_info" :key="index">
                    <div class="prize-p">
                        {{item.prize_name}}，共{{item.win_number}}人
                    </div>
                </template>
                <div class="draw_label six">
                    <div class="strong">抽奖规则<text class="draw_rule">需满足全部条件</text></div>
                </div>
                <div class="prize-ul">
                    <div v-for="(item, index) in ruleArr" :key="index" class="prize-li">
                        <div class="prize-li-label">
                            {{index + 1}}. {{item.title}}
                        </div>
                        <div v-if="item.label">
                            <div v-if="updateInfo.activity_info.rule_two && item.label.indexOf('分享') > 1" class="share-container">
                                <div class="draw_label">
                                    <div class="strong label-title">分享方式：</div>
                                </div>
                                <div class="prize-tips prize-tips-label">{{item.label}}</div>
                                <div class="eg_cover lp_flex lptems_center lp_justify lptems_center" style="border-bottom: none;margin-bottom: 0;padding-bottom: 0;">
                                    <img class="prize-img" src="https://s.moimg.net/images/draw_cover_1.png" />
                                    <img class="prize-img" src="https://s.moimg.net/images/draw_cover_2.png" />
                                </div>
                            </div>
                            <div v-else-if="updateInfo.activity_info.reward_name" class="share-container">
                                <div class="draw_label">
                                    <div class="strong label-title">支持/购买方式：</div>
                                </div>
                                <div class="prize-tips prize-tips-label">{{item.label}}</div>
                            </div>
                            <span v-else>{{item.label}}</span>
                        </div>
                    </div>
                </div>
                <div class="draw_label six">
                    <div class="strong">开奖时间</div>
                </div>
                <div class="prize-ul">
                    <div v-if="updateInfo.activity_info.draw_time" class="prize-li">
                        {{updateInfo.activity_info.draw_time}}
                    </div>
                </div>
                <template v-if="userId">
                    <template v-if="updateInfo.activity_info.if_success == 2">
                        <div class="show_draw_btn" @click="showResult">
                            查看抽奖结果
                        </div>
                    </template>
                    <template v-else-if="updateInfo.activity_info.if_success == 0">
                        <div class="show_draw_btn" @click="showResult">
                            {{updateInfo.activity_info.if_draw == 1 ? '邀请好友助力' : '参与抽选'}}
                        </div>
                        <!-- <div v-if="updateInfo.activity_info.if_draw == 1" class="show_draw_btn" @click="showResult">
                            邀请好友助力
                        </div>
                        <div v-else class="show_draw_btn" @click="showResult">
                            参与抽选
                        </div> -->
                    </template>
                </template>
                <template v-else>
                    <div class="show_draw_btn" @click="showResult">
                        参与抽选
                    </div>
                </template>
                <div class="tips">注：助力值越高，中奖机会越大</div>
                <div class="line"></div>
            </div>
        </template>
    </div>
</template>

<script>
import { ElMessageBox,ElMessage } from "element-plus";
import { mapState } from "vuex"
export default {
    components: {

    },
    props: {
        activity_info: {
            type: Object
        },
        updateInfo: {
            type: Object
        }
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId,
        }),
        ruleArr() {
            let ruleArr = []
            if (this.updateInfo.type == 2) {
                if(this.activity_info.rule_one) {
                    ruleArr.push({
                        title: '关注项目发起者',
                        label: ''
                    })
                }
                
                if(this.activity_info.rule_two) {
                    ruleArr.push({
                        title: '分享项目',
                        label: '分享'
                    })
                }
                if(this.activity_info.reward_name || Number(this.activity_info.full_money) > 0) {
                    ruleArr.push({
                        title: '支持/购买项目',
                        label: this.activity_info.reward_name
                    })
                }
                if(this.activity_info.rule_five && this.activity_info.rule_five.topic) {
                    ruleArr.push({
                        title: '带话题参与项目讨论',
                        label: this.activity_info.rule_five.topic
                    })
                }
            } else if(this.updateInfo.type == 3){
                if(this.activity_info.rule_one) {
                    ruleArr.push({
                        title: '关注项目发起者',
                        label: ''
                    })
                }
                if(this.activity_info.rule_four) {
                    ruleArr.push({
                        title: '看好项目',
                        label: ''
                    })
                }
                if(this.activity_info.rule_two) {
                    ruleArr.push({
                        title: '分享项目',
                        label: this.activity_info.rule_two
                    })
                }
                if(this.activity_info.reward_name) {
                    ruleArr.push({
                        title: '付费支持本项目的任一回报档：',
                        label: this.activity_info.reward_name
                    })
                }
                if(this.activity_info.more_explain && this.activity_info.more_explain.length > 0) {
                    for(let i = 0; i < this.activity_info.more_explain.length; i++){
                        ruleArr.push({
                            title: this.activity_info.more_explain[i],
                            label: ''
                        })
                    }
                }
            }
            return ruleArr
        },
        imgs() {
            return this.activity_info.img
        }
    },
    data() {
        return {

        }
    },
    methods: {
        showRule() {
            let tipsStr = '<p>为保证抽奖活动的公开、公平、公正，现将抽奖参与者相关规则说明如下：</p>';
            tipsStr += '<p>1. 发起者应在抽奖活动中公布的预计发奖期限内发奖，发奖时间不得晚于下述期限：</p>';
            tipsStr += '<p>(1) 众筹项目，发奖时间不得晚于中款结算之日；</p>';
            tipsStr += '<p>(2) 创意项目，发奖时间不得晚于开奖后7日。</p>';
            tipsStr += '<p>2. 如奖品为实物奖品，中奖用户需自收到中奖通知之日起7日内提交准确的收货地址，否则视为自愿放弃中奖资格。</p>';
            tipsStr += '<p>3. 当您参与带话题评论抽奖活动时，如您的评论中含有（包括但不限于）辱骂、色情、涉政等内容，系统抽奖工具将会自动排除掉您的评论，该评论无法获得抽奖资格，请友善评论，文明发言。</p>';
            ElMessageBox.confirm(
                tipsStr,
                "抽奖规则",
                {
                    autofocus: false,
                    showCancelButton: false,
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: "确定",
                }
            ).then(() => { });
        },
        // 查看抽奖结果 -- 普通抽奖
        /* eslint-disable */
        showDrawResult() {
            if(this.updateInfo.activity_info.if_success == 0) {
                ElMessage({
                    type: 'warning',
                    customClass: 'waring-customize',
                    message: '项目发起者还没开奖哦，请耐心等待开奖结果',
                });
            } else {
                const active_id = this.updateInfo.prize_info[0].active_id
            }
        },
        // 参与抽奖  -- 助力抽奖
        showResult() {
            const active_id = this.updateInfo.prize_info[0].active_id
        },
        getText(text, matched_item) {
            var str = text
            if (matched_item) {
                const arr = matched_item.split(',')
                arr.map(item => {
                    var reg = new RegExp(item, "g")
                    str = str.replace( reg, '<span style="color: #00C4A1;">'+item+'</span>')
                })
            }
            return str
        },
        showBigImg (index) {
            const _this = this
            console.log(index)
        }
    }
}
</script>

<style lang="scss" scoped>
/* 抽奖 */
.draw_detail{
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    padding: 20px 15px;
    background: #F8F8F9;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    margin-bottom: 15px;
    color: #222C37;
}
.draw_detail .draw_label .strong{
    font-size: 18px;
    font-weight: bold;
}
.draw_detail .draw_label .strong .draw_rule {
    font-size: 12px;
    font-weight: 400;
    color: #F58500;
    margin-left: 10px;
}
.draw_detail .draw_label .strong.label-title {
    font-size: 16px;
    font-weight: 400;
}
.draw_detail .draw_label{
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
}
.draw_label.draw_label_mode {
    margin: 60px 0 10px;
}
.draw_detail .draw_label .rule{
    font-size: 24px;
    color: #425EB2;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.draw_detail .draw_label .rule .iconfont{
    font-size: 12px;
    margin-top: 2px;
    margin-left: 4px;
}
.draw_detail .draw_label_time {
    font-size: 14px;
    font-weight: 400;
    color: #F58500;
}
.draw_detail .draw_label_time .time-label {
    margin-top: 8px;
}
.draw_detail .prize-p{
    padding-top: 15px;
    font-size: 14px;
    margin: 0;
    color: #222C37;
}
.draw_detail.draw_detail_lottery .prize-p{
    font-size: 16px;
    color: #222C37;
}
.draw_detail .six{
    margin-top: 30px;
}
.draw_detail .prize-ul{
    position: relative;
}
.draw_detail .prize-ul .prize-li{
    font-size: 14px;
    padding-top: 13px;
    color: #7A8087;
    .prize-li-label {
        color: #222C37;
        font-weight: bold;
    }
}

.apple-des {
    font-size: 14px;
    padding-top: 13px;
    color: #222C37;
    font-weight: normal;
}
.draw_detail.draw_detail_lottery .prize-ul .prize-li{
    font-size: 16px;
    color: #222C37;
    font-weight: bold;
}
.prize-li text{
    display: inline-block;
    margin-right: 10px;
}
.share-container {
    width: 690px;
    background: #FFFFFF;
    border: 1px solid #DEE0E1;
    border-radius: 6px;
    padding: 30px 20px;
    box-sizing: border-box;
    margin-top: 10px;
}
.draw_detail .show_draw_btn{
    margin-top: 60px;
    width: 100%;
    height: 40px;
    background: #00CBA6;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    color: #fff;
    cursor: pointer;
    position: relative;
    span{
        font-size: 14px;
        padding-right: 6px;
    }
}
.draw_detail .tips{
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #F58500;
    padding: 10px 0 20px 0;
}
.prize-tips{
    font-size: 12px;
    font-weight: 400;
    color: #797F87;
    margin-top: 6px;
}
.prize-tips.prize-tips-label {
    font-size: 14px;
    color: #7A8087;
}
.prize-tips-line {
    width: 650px;
    height: 1px;
    background: #DEE0E1;
    margin: 10px auto;
}
.draw_detail .eg_cover{
    overflow: hidden;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #DEE0E1;
}
.draw_detail .eg_cover .prize-img{
    width: 320px;
    height: 423px;
}
.prize-img-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding:0 30px;
    margin: 20px 0 24px;
    img {
        object-fit: cover;
    }
}
.prize-img-list .one-img{
    width:100%;
}
.prize-img-list .two-img{
    width: 340px;
    height: 340px;
    margin-bottom: 10px;
    margin-right:10px;
}
.prize-img-list .two-img:nth-of-type(2n){
    margin-right:0;
}
.prize-img-list .three-img{
    width:222px;
    height:224px;
    margin-right:10px;
    margin-bottom: 10px;
}
.prize-img-list .three-img:nth-of-type(3n){
    margin-right:0;
}
</style>
