<template>
    <div>
        <div>
            <div ref="editorBox"></div>
            <Draw v-if="hasActivityInfo" :update-info="update_info" :type="update_info.type" :activity_info="activity_info" />
        </div>
    </div>
</template>

<script>
import store from "@/store"
import { createApp } from 'vue'
import Draw from "@@/update/detail/draw.vue"
import Quest from "@@/update/detail/quest.vue"
import DrawNew from "@@/update/detail/drawNew.vue"
import ElementPlus from 'element-plus'
import { ElImage, ElDialog } from "element-plus";
import cont_match_reg from "@/utils/editor.js"
import { getProjectUpdateDetail } from "@api/index"
import { observeVideo } from "md-base-tools/editor/gitvideo"

export default {
    props: {
        noPost: {
            type: Boolean,
            default: false
        },
        updateInfo: {
            type: Object
        }
    },
    components: {
        Draw
    },
    computed: {
        hasActivityInfo() {
            return this.activity_info && JSON.stringify(this.activity_info) != "{}"
        },
        hasQuestInfo() {
            return this.quest_info && this.quest_info.length
        }
    },
    data() {
        return {
            update_id: '',
            showError: false,
            showMessage: "",
            update_info: {}, // 更新详情
            content: '', // 富文本信息
            activity_info: {}, // 活动详情,
            quest_info: [], // 投票详情
        }
    },
    async mounted() {
        const update_id = this.$route.params.id ? this.$route.params.id.replace('.html', '') : ''
        this.update_id = update_id
        
        if (this.noPost) {
            const _this = this
            this.update_info = this.updateInfo
            this.quest_info = this.updateInfo.quest_info instanceof Array ? this.updateInfo.quest_info : []
            this.activity_info = this.updateInfo.activity_info
            _this.$nextTick(() => {
                if (!_this.showError && _this.$refs.editorBox) {
                    _this.$refs.editorBox.appendChild(cont_match_reg(_this.updateInfo.content))
                    // 抽奖
                    const list = document.getElementsByClassName('draw_content')
                    if (list && list.length) {
                        for(let i = 0; i < list.length; i++) {
                            const drawId = list[i].getAttribute("data-draw-id")
                            const drawInfo = _this.updateInfo.draw_list.find(item => item.activity_id == drawId)
                            createApp(DrawNew, {
                                drawInfo: drawInfo
                            }).use(store).use(ElementPlus).use(ElDialog).mount(list[i])
                        }
                    }
                    if (this.hasQuestInfo) {
                        if (document.getElementById("vote_pos")) {
                            createApp(Quest, {
                                id: this.update_id,
                                quest_info: this.quest_info[0],
                                vote_type: '2',
                            }).use(store).mount(`#vote_pos`)
                        } else {
                            for (let i = 0; i < this.quest_info.length; i++) {
                                createApp(Quest, {
                                    id: this.update_id,
                                    quest_info: this.quest_info[i],
                                    vote_type: '2',
                                }).use(store).use(ElementPlus).use(ElImage).mount(`#vote_pos_${this.quest_info[i].id}`)
                            }
                        }       
                    }
                }
            })
            return
        }
        console.log(999)
        const {data, status, message} = await getProjectUpdateDetail({
            update_id: update_id
        })
        if (status == 0) {
            const update_info = data
            console.log(data)
            this.update_info = update_info

            if (!(update_info.content.indexOf('<p') == 0 && update_info.content.lastIndexOf('</p>') == update_info.content.length - 4)){
                update_info.content = `<p>${update_info.content}</p>`
            }

            this.content = update_info.content || ''
            this.activity_info = update_info.activity_info
            this.quest_info = update_info.quest_info instanceof Array ? update_info.quest_info : []
        } else if (status == '999999') {
            this.showError = true
            this.showMessage = "服务错误"
            this.sensors.track('ProjectClientDetail', {
                Type: "服务错误"
            });
        } else {
            this.showError = true
            this.showMessage = message || "什么都没有哦"
            this.sensors.track('ProjectClientDetail', {
                Type: "接口错误",
                Message: message
            });
        }
        const _this = this
        _this.$nextTick(() => {
            if (!_this.showError && _this.$refs.editorBox) {
                _this.$refs.editorBox.appendChild(cont_match_reg(this.content))
                this.$nextTick(() => {
                    observeVideo(_this.$refs.editorBox, process.env.VUE_APP_ENV)
                })
                if (this.hasQuestInfo) {
                    if (document.getElementById("vote_pos")) {
                        createApp(Quest, {
                            id: this.update_id,
                            quest_info: this.quest_info[0],
                            vote_type: '2',
                        }).use(store).mount(`#vote_pos`)
                    } else {
                        for (let i = 0; i < this.quest_info.length; i++) {
                            createApp(Quest, {
                                id: this.update_id,
                                quest_info: this.quest_info[i],
                                vote_type: '2',
                            }).use(store).use(ElementPlus).use(ElImage).mount(`#vote_pos_${this.quest_info[i].id}`)
                        }
                    }       
                }
            }
        })
    }
}
</script>
