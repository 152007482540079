<template>
    <div class="update-detail" v-loading="update_loading">
        <img :src="update_info.logo">
        <div class="des">
            <span>{{update_info.num}}</span>
            <img v-if="update_info.type == 2" src="https://p6.moimg.net/mdwxapp/update_activity.png" />
            <img v-else-if="update_info.type == 3" src="https://p6.moimg.net/mdwxapp/update_activity_help.png" />
            <div class="time">{{update_info.ctime}}</div>
        </div>
        <div class="title">{{update_info.title}}</div>
        <div class="user">
            <img :src="user_info.icon ? user_info.icon : 'https://s.moimg.net/new/images/headPic.png'" @click.stop="goHomePage(user_info.id)" />
            <p>{{user_info.username}}</p>
        </div>
        <CompClientDetail v-if="update_info && JSON.stringify(update_info) != '{}'" :noPost="true" :update-info="update_info" />
        <div v-if="Number(product_info.pro_class) != 102 && !update_loading" class="project-detail" @click="goPro">
            <div class="from-box">
                <span>来源项目</span>
                <div class="render-line-clamp">{{update_info.pro_title}}</div>
            </div>
            <img :src="product_info.wb_logo_1" />
        </div>
        <div :class="['big_like_btn',{'active':update_info.is_like}]" @click.stop="favorUpdate">
            <i class="icon-favour"></i>
            <span>{{ update_info.is_like ? '已赞' : '赞一下'}}</span>
        </div>
        <div v-if="update_info.like_count != 0" class="appraise">
            <img 
                class="img" 
                v-for="(item, index) in update_info.uid_list" 
                :key="index" 
                :src="item.icon"
                @click.stop="goHomePage(item.id)" />
            <div class="icon">{{update_info.like_count}}个赞</div>
        </div>
        <div class="comment_btn">
            <el-button type="primary" @click.stop="goCOmment">{{update_info.comment_count > 0 ? '查看' + update_info.comment_count + '条评论' : '暂无评论，抢沙发'}}</el-button>
        </div>
    </div>
</template>

<script>
import domain from '@/service/domain'
import { getUpdateDetail, postFavorUpdate } from '@/api/index'
import { mapState } from 'vuex'
import CompClientDetail from "./editor.vue"
import { ElMessageBox } from "element-plus";
export default {
    computed: {
        ...mapState({
            userId: state => state.user.userId
        })
    },
    components: {
        CompClientDetail,
    },
    data() {
        return {
            showError: false,
            showMessage: '',
            update_id: '',
            is_from_acp: '',
            update_info: {},
            user_info: {},
            product_info: {},
            update_loading: true
        }
    },
    async created() {
        document.title = '更新详情'
        const update_id = this.$route.params.id || ''
        const is_from_acp = this.$route.query.is_from_acp || 0
        this.update_id = update_id
        this.is_from_acp = is_from_acp
        const res = await getUpdateDetail({
            update_id: this.update_id,
            is_from_acp: this.is_from_acp
        })
        const _this = this
        if (res.status == 0) {
            this.update_info = res.data
            this.user_info = res.data.user_info
            this.product_info = res.data.product_info
            this.update_loading = false
        } else {
            this.showError = true
            if (res.message == '您没有权限查看此更新') {
                this.showMessage = res.message + (_this.userId ? '' : '，请登录后重试')
            } else {
                this.showMessage = res.message || ''
            }
            ElMessageBox.confirm(
                this.showMessage,
                "提示",
                {
                showCancelButton: false,
                confirmButtonText: "好的",
                }
            ).then(() => {
                window.location.href = 'https://www.modian.com/';
            });
        }
    }, 
    methods: {
        async favorUpdate() {
            const { status, data, message } = await postFavorUpdate({
                update_id: this.update_id,
                favor_uid: this.update_info.user_info && this.update_info.user_info.id || 0,
            })
            if (status == 0) {
                this.update_info.is_like = data;
                const res = await getUpdateDetail({
                    update_id: this.update_id
                })
                if (res.status == 0) {
                    this.update_info.uid_list = res.data.uid_list
                    this.update_info.like_count = res.data.like_count
                }
            }else{
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 去项目详情
        goPro() {
            window.location.href = domain.zhongchou + '/item/'+this.update_info.pro_id+'.html'
        },
        toUserhome(item) {
            console.log(item)
        },
        goCOmment(){
            let newTab = window.open('about:blank');
            let commentUrl = domain.zhongchou + '/p/update_detail_comment/'+this.update_info.pro_id+'/'+this.update_id;
            if(/is_from_acp=1/.test(location.href)){
                commentUrl += '?is_from_acp=1'
            }
            newTab.location.href = commentUrl
        },
        goHomePage(id){
            let newTab = window.open('about:blank');
            newTab.location.href = '/user/homepage/'+id+'.html';
        }
    }
}
</script>

<style lang="scss" scoped>
.update-detail {
    max-width: 690px;
    margin: 0 auto;
    width: 690px;
    height: auto;
    >img{
        width:100%;
    }
    p{
        margin:0;
    }
    .des {
        display: flex;
        align-items: center;
        padding-top: 36px;
        font-size: 16px;
        color: #7a8087;
        line-height: 16px;
        img {
            height: 14px;
            margin-left: 5px;
        }   
        .time {
            margin-left: auto;
        }
    }
    .title {
        color: #222c37;
        font-size: 34px;
        line-height: 51px;
        font-weight: 700;
        margin: 32px 0 12px 0;
    }
    .user {
        display:flex;
        align-items:center;
        width:100%;
        padding-bottom:40px;
        img {
            cursor: pointer;
            float:left;
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
        >p {
            float:left;
            font-size: 16px;
            line-height: 40px;
            color: #222c37;
            padding: 0 10px;
        }
    }
    .comment_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom:60px;
    }
    .project-detail {
        width: 100%;
        height: 112px;
        background: #F8F8F9;
        padding: 20px;
        box-sizing: border-box;
        margin: 40px auto;
        display: flex;
        justify-content: space-between;
        img {
            width: 72px;
            height: 72px;
            object-fit: cover;
        }
        .from-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 72px);
            height: 72px;
            span {
                font-size: 16px;
                color: #7a8087;
                line-height: 16px;
                height: 16px;
                margin-top: 4px;
                background: url('https://s.moimg.net/mdwxapp/crowdfunding-update-link@2x.png') no-repeat left center;
                background-size: 16px;
                text-indent:21px;
            }
            div {
                margin-top: 4px;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                height: 48px;
                color: #222c37;
                display: flex;
                /* align-items: center; */
                justify-content: space-around;
                flex-direction: column;
            }
        }
    }
    .appraise {
        padding: 20px;
        box-sizing: border-box;
        width: 690px;
        height: 80px;
        background: #f8f8f9;
        overflow: hidden;
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            cursor: pointer;
        }
        .icon {
            margin-left: auto;
            color: rgba(87, 107, 149, 1);
            font-size: 16px;
        }
    }
    .big_like_btn {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: solid 2px #7a8087;
        background: #fff;
        text-align: center;
        margin: 0 auto;
        position: relative;
        margin-bottom: 60px;
        cursor: pointer;
        i{
            color: #7A8087;
            font-size: 20px;
            line-height: 58px;
        }
        &.active{
            border: solid 2px #00cba6;
            background: #00D6A6;
            i{
                color:#fff;
            }
            span{
                color:#00D6A6;
            }
        }
        span {
            position: absolute;
            font-size: 14px;
            line-height: 14px;
            color: #222c37;
            min-width: 43px;
            top: 70px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    ::v-deep(.html-from-editor){
        font-size:16px;
        ul{
            list-style: disc;
            padding: 0 0 0 15px;
            li{
                list-style:disc;
            }
        }
        .video_field {
            width: 100%;
            position: relative;
            background-color: #000;
            height: 100%;
            video {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            .video_cover {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: no-repeat 50%;
                background-size: cover;
                .play_icon {
                    display: block;
                    width: 60px;
                    height: 60px;
                    background: url(https://s.moimg.net/m/images/video-play.png) no-repeat 50%;
                    background-size: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
        .audio_field {
            height: 106px;
            width: calc(100% - 60px);
            padding: 15px;
            align-items: center;
            display: flex;
            border: 1px solid #f2f2f2;
            border-radius: 8px;
            box-sizing: border-box;
            .audio_cover {
                width: 76px;
                height: 76px;
                margin-right: 30px;
                div {
                    width: 76px;
                    height: 76px;
                    background: no-repeat 50%;
                    background-size: 100%;
                    &.play{
                        background-image: url(https://p6.moimg.net/mdwxapp/audio_play_icon.png);
                    }
                    &.pause {
                        background-image: url(https://p6.moimg.net/mdwxapp/audio_pause_icon.png);
                    }
                }
            }
            .audio_content {
                width: 0;
                flex: 1;
                display: flex;
                flex-direction: column;
                .title {
                    font-size: 18px;
                }
                .content_btm {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    margin-top: 15px;
                    .progress {
                        height: 4px;
                        flex: 1;
                        margin-right: 10px;
                        background-color: #e8e8e8;
                        .current {
                            height: 4px;
                            background: linear-gradient(270deg,#21b6c7,#00cba6);
                            position: relative;
                        }
                    }
                    .time {
                        color: #b1b1b1;
                    }
                }
            }
        }
        .nav_img{
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
        }
        .nav_img.clearance{
            margin: 10px;
        }
        .nav_img>img{
            height: 478px;
            display: inline-block;
            max-width: none;
        }
        .nav_img>img.clearance{
            margin-right: 5px;
        }
        .nav_img>img.clearance:last-child{
            margin: 0;
        }
    }
}
</style>
